import React from "react"
import { feedback } from "../constants"
import styles from "../styles/style"
import FeedbackCard from "./feedback-card"

const Testimonials = () => (
  <div className={`bg-primary mb-12 ${styles.flexCenter}`}>
    <div className={`${styles.boxWidth}`}>
      <section
        id="clients"
        className={` ${styles.flexCenter} flex-col relative`}
      >
        {/* <div className="absolute z-[0] w-[30%] h-[30%] -right-[40%] rounded-full blue__gradient bottom-0" /> */}

        <div className="w-full mb-6 flex justify-between items-center md:flex-row flex-col relative z-[1]">
          <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full">
            <span className="text-green-gradient">What people are</span>
            <br className="xx:block hidden" />{" "}
            <span className="text-green-gradient">saying about us</span>{" "}
          </h2>
          <div className="w-full md:mt-0 mt-4">
            <p
              className={`${styles.paragraph2} text-left lg:max-w-[460px] max-w-[100%]`}
            >
              All you need to know about H&S Pepper Co. Who we are, our passion
              and love for what we do, why we do this, and the the products we
              produce.
            </p>
          </div>
        </div>

        <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
          {feedback.map((card) => (
            <FeedbackCard key={card.id} {...card} />
          ))}
        </div>
      </section>
    </div>
  </div>
)

export default Testimonials
