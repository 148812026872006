import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
//import { StaticImage } from "gatsby-plugin-image"
//import styles from "../styles/style"

const FeaturedProducts = ({ products }) => {
  //console.log("Featured Products: ", products)
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mb-8 gap-2">
      {products.map((product, index) => {
        //console.log("Product: ", product.title)
        return (
          <Link
            key={index}
            to={`${product.slug}`}
            className="p-2 bg-white rounded-md shadow-md hover:opacity-50 "
          >
            <div className="relative overflow-hidden">
              <GatsbyImage
                image={product.images[0].gatsbyImageData}
                alt={product.title}
                className=""
                style={{ borderRadius: "5px" }}
              />
            </div>
            <div className="relative ">
              <div className="py-4 px-4">
                <h3 className="font-semibold capitalize  text-[20px] mb-2">
                  <span className="text-blue-gradient">{product.title}</span>
                </h3>

                <h4 className="font-semibold capitalize text-[16px] mb-2 mt-2">
                  <p className=" font-bold italic mt-2 mb-2">from £8.50</p>
                </h4>

                <p className="font-poppins font-normal text-[#152238] text-[14px] leading-[22.8px]">
                  {product.description}
                </p>

                <footer className="flex items-center justify-between leading-none mt-4">
                  <div className="flex items-center no-underline  text-[#152238]">
                    <img
                      alt="Placeholder"
                      className="block rounded-full"
                      src="https://picsum.photos/32/32/?random"
                    />
                    <p className="ml-2 text-[#152238] text-sm invisible xx:visible">
                      {product.vendor}
                    </p>
                  </div>
                  <div className="flex justify-between text-sm items-center no-underline">
                    <p className="mr-4 text-[#152238]">View Item</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.125"
                      height="13.358"
                      viewBox="0 0 14.125 13.358"
                    >
                      <g transform="translate(-3 -3.293)">
                        <path
                          id="Path_7"
                          data-name="Path 7"
                          d="M14.189,10.739H3V9.2H14.189L9.361,4.378l1.085-1.085,6.679,6.679-6.679,6.679L9.361,15.566Z"
                          fill="#1d1d1d"
                          fillRule="evenodd"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </footer>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default FeaturedProducts
