import * as React from "react"
import { graphql, Link } from "gatsby"
import { Layout } from "../../../components/layout"
import isEqual from "lodash.isequal"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { StoreContext } from "../../../context/store-context"
import { AddToCart } from "../../../components/add-to-cart"
import { NumericInput } from "../../../components/numeric-input"
import { formatPrice } from "../../../utils/format-price"
import { Seo } from "../../../components/seo"
import { CgChevronRight as ChevronIcon } from "react-icons/cg"
import {
  productBox,
  container,
  header,
  productImageWrapper,
  productImageList,
  productImageListItem,
  scrollForMore,
  noImagePreview,
  optionsWrapper,
  priceValue,
  selectVariant,
  labelFont,
  breadcrumb,
  tagList,
  addToCartStyle,
  metaSection,
  productDescription,
} from "./product-page.module.css"
import styles from "../../../styles/style"
import FeaturedProducts from "../../../components/featured-products"
import Testimonials from "../../../components/Testimonials"

export default function Product({ data: { product, suggestions } }) {
  //console.log("Suggestions: ", suggestions.nodes)
  const suggestedProducts = suggestions.nodes
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    images,
    images: [firstImage],
  } = product
  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0
  const hasMultipleImages = true || images.length > 1

  return (
    <Layout>
      <div className={` ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          {firstImage ? (
            <Seo
              title={title}
              description={description}
              image={getSrc(firstImage.gatsbyImageData)}
            />
          ) : undefined}
          <div className={`${styles.paddingX} ${styles.flexCenter}`}>
            <div className={productBox}>
              {hasImages && (
                <div className={productImageWrapper}>
                  <div
                    role="group"
                    aria-label="gallery"
                    aria-describedby="instructions"
                  >
                    <ul className={productImageList}>
                      {images.map((image, index) => (
                        <li
                          key={`product-image-${image.id}`}
                          className={productImageListItem}
                        >
                          <GatsbyImage
                            objectFit="cover"
                            loading={index === 0 ? "eager" : "lazy"}
                            alt={
                              image.altText
                                ? image.altText
                                : `Product Image of ${title} #${index + 1}`
                            }
                            image={image.gatsbyImageData}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                  {hasMultipleImages && (
                    <div className={scrollForMore} id="instructions">
                      <span aria-hidden="true">←</span> scroll for more{" "}
                      <span aria-hidden="true">→</span>
                    </div>
                  )}
                </div>
              )}
              {!hasImages && (
                <span className={noImagePreview}>No Preview image</span>
              )}
              <div>
                <div className={`mb-4 ${breadcrumb}`}>
                  <Link to={product.productTypeSlug}>
                    {product.productType}
                  </Link>
                  <ChevronIcon size={12} />
                </div>
                <h1 className={`text-green-gradient ${header}`}>{title}</h1>
                <p className={productDescription}>{description}</p>
                <h2 className={`text-green-gradient ${priceValue}`}>
                  <span>{price}</span>
                </h2>
                <fieldset className={optionsWrapper}>
                  {hasVariants &&
                    options.map(({ id, name, values }, index) => (
                      <div className={selectVariant} key={id}>
                        <select
                          aria-label="Variants"
                          onChange={(event) => handleOptionChange(index, event)}
                        >
                          <option value="">{`Select ${name}`}</option>
                          {values.map((value) => (
                            <option value={value} key={`${name}-${value}`}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>
                    ))}
                </fieldset>
                <div className={addToCartStyle}>
                  <NumericInput
                    aria-label="Quantity"
                    onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
                    onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
                    onChange={(event) => setQuantity(event.currentTarget.value)}
                    value={quantity}
                    min="1"
                    max="20"
                  />
                  <AddToCart
                    variantId={productVariant.storefrontId}
                    quantity={quantity}
                    available={available}
                  />
                </div>
                <div className={`${metaSection}`}>
                  {/* <span className={labelFont}>Type</span>
                  <span className={breadcrumb}>
                    <Link to={product.productTypeSlug}>
                      {product.productType}
                    </Link>
                    <ChevronIcon size={18} />
                  </span> */}
                  <span className={`${labelFont}`}>Tags</span>
                  <span className={tagList}>
                    {product.tags.map((tag, index) => (
                      <Link key={index} to={`mr-5/search?t=${tag}`}>
                        <span className="">{tag}</span>
                      </Link>
                    ))}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.paddingX} ${styles.flexCenter}`}>
            <div className={`${styles.boxWidth}`}>
              <h2 className="font-poppins font-semibold text-[20px] xx:text-[30px] xs:text-[32px]  ss:text-[36px] lg:text-[40px] xl:text-[46px] w-full">
                <span className="text-green-gradient">Suggested Items</span>
              </h2>
              <FeaturedProducts products={suggestedProducts} />
            </div>
          </div>
          <div className={`${styles.paddingX} ${styles.flexCenter}`}>
            <div className={`${styles.boxWidth}`}>
              <Testimonials />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        # altText
        id
        gatsbyImageData(layout: CONSTRAINED, width: 640, aspectRatio: 1)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
    # products: shopifyCollection(handle: { eq: "home-page" }) {
    #  products {
    #    ...ProductCard
    #    description
    #  }
    #}
  }
`
