import React from "react"
import quotes from "../images/quotes.svg"
import styles from "../styles/style"

const FeedbackCard = ({ content, name, title, img }) => (
  <div className="flex justify-between flex-col px-10 py-12 rounded-[20px]    feedback-card">
    <img
      src={quotes}
      alt="double_quotes"
      className="w-[42.6px] h-[27.6px] object-contain"
    />
    <p className={`${styles.paragraph3} text-white text-left my-8`}>
      {content}
    </p>

    <div className="flex flex-row">
      <img src={img} alt={name} className="w-[48px] h-[48px] rounded-full" />
      <div className="flex flex-col ml-4">
        <h4 className="font-poppins font-bold text-[20px] leading-[32px] text-gradient">
          {name}
        </h4>
        <p className="font-poppins font-normal text-[16px] leading-[24px] text-primary">
          {title}
        </p>
      </div>
    </div>
  </div>
)

export default FeedbackCard
